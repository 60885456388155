const employmentStatusChoices = [
  { label: "Fresh", value: "Fresh" },
  { label: "Employed - Full Time", value: "Employed - Full Time" },
  { label: "Employed - Part Time", value: "Employed - Part Time" },
  { label: "Unemployed", value: "Unemployed" },
  { label: "Retired - Employed", value: "Retired - Employed" },
  { label: "Retired - Unemployed", value: "Retired - Unemployed" },
  { label: "Intern", value: "Intern" },
  { label: "Freelancer", value: "Freelancer" },
];

const EXPERIENCE_CHOICES = [
  { value: "Fresh", label: "Fresh" },
  { value: "6 Month", label: "6 Month" },
  { value: "1 Years", label: "1 Years" },
  { value: "2 Years", label: "2 Years" },
  { value: "3 Years", label: "3 Years" },
  { value: "4 Years", label: "4 Years" },
  { value: "5 Years", label: "5 Years" },
  { value: "6 Years", label: "6 Years" },
  { value: "7 Years", label: "7 Years" },
  { value: "8 Years", label: "8 Years" },
  { value: "9 Years", label: "9 Years" },
  { value: "10+ Years", label: "10+ Years" },
];

const STATUS = [
  { label: "Approved", value: "Approved" },
  { label: "Reject", value: "Reject" },
  { label: "Pending", value: "Pending" },
  { label: "Expire", value: "Expire" },
];
const FUNCTIONAL_AREA_CHOICES = [
  { label: "Accounts & Finance", value: "Accounts & Finance" },
  { label: "Administration", value: "Administration" },
  { label: "Advertising", value: "Advertising" },
  { label: "Construction", value: "Construction" },
  { label: "Client Support", value: "Client Support" },
  { label: "Networking", value: "Networking" },
  { label: "Corporate Affairs", value: "Corporate Affairs" },
  { label: "Creative Design", value: "Creative Design" },
  { label: "Data Entry", value: "Data Entry" },
  { label: "Database Admin", value: "Database Admin" },
  { label: "Distribution & Logistics", value: "Distribution & Logistics" },
  { label: "Engineering", value: "Engineering" },
  { label: "Exec Management", value: "Exec Management" },
  { label: "Field Operations", value: "Field Operations" },
  { label: "Hardware", value: "Hardware" },
  { label: "Health & Medicine", value: "Health & Medicine" },
  { label: "Hotel/Restaurant Mgmt", value: "Hotel/Restaurant Mgmt" },
  { label: "Human Resources", value: "Human Resources" },
  { label: "Import & Export", value: "Import & Export" },
  { label: "Industrial Prod", value: "Industrial Prod" },
  { label: "Intern", value: "Intern" },
  { label: "Investment Ops", value: "Investment Ops" },
  { label: "Legal Affairs", value: "Legal Affairs" },
  { label: "Maintenance/Repair", value: "Maintenance/Repair" },
  { label: "Mgmt Consulting", value: "Mgmt Consulting" },
  { label: "MIS", value: "MIS" },
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Maritime & Shipping", value: "Maritime & Shipping" },
  { label: "Marketing", value: "Marketing" },
  { label: "Media - Print & Electronic", value: "Media - Print & Electronic" },
  { label: "Merchandising", value: "Merchandising" },
  { label: "Monitoring & Evaluation", value: "Monitoring & Evaluation" },
  { label: "Operations", value: "Operations" },
  { label: "Planning & Development", value: "Planning & Development" },
  { label: "Procurement", value: "Procurement" },
  { label: "Product Dev", value: "Product Dev" },
  { label: "Product Mgmt", value: "Product Mgmt" },
  { label: "Project Mgmt", value: "Project Mgmt" },
  { label: "Public Relations", value: "Public Relations" },
  { label: "Quality Assurance", value: "Quality Assurance" },
  { label: "Quality Control", value: "Quality Control" },
  { label: "Retail", value: "Retail" },
  { label: "Safety & Environment", value: "Safety & Environment" },
  { label: "Sales & Biz Dev", value: "Sales & Biz Dev" },
  { label: "SEO", value: "SEO" },
  { label: "Secretarial & Clerical", value: "Secretarial & Clerical" },
  { label: "Security", value: "Security" },
  { label: "Software & Web Dev", value: "Software & Web Dev" },
  { label: "Supply Chain Mgmt", value: "Supply Chain Mgmt" },
  { label: "Systems Analyst", value: "Systems Analyst" },
  { label: "Teaching & Training", value: "Teaching & Training" },
  { label: "Telemarketing", value: "Telemarketing" },
  { label: "Warehousing", value: "Warehousing" },
  { label: "Writer", value: "Writer" },
  { label: "Social Sciences", value: "Social Sciences" },
  { label: "Compliance", value: "Compliance" },
  { label: "Social Mobilization", value: "Social Mobilization" },
  { label: "Media & Communication", value: "Media & Communication" },
  { label: "Communication", value: "Communication" },
  { label: "Housekeeping", value: "Housekeeping" },
  { label: "Sports", value: "Sports" },
  { label: "Health & Fitness", value: "Health & Fitness" },
  { label: "Yoga", value: "Yoga" },
  { label: "Body Building", value: "Body Building" },
  { label: "IT Support", value: "IT Support" },
  { label: "Programming", value: "Programming" },
  { label: "Medical Tech", value: "Medical Tech" },
  { label: "Food & Nutrition", value: "Food & Nutrition" },
  { label: "Research", value: "Research" },
  { label: "Receptionist", value: "Receptionist" },
  { label: "Domestic Aid", value: "Domestic Aid" },
  { label: "Data Analytics", value: "Data Analytics" },
];
const INDUSTRY_CHOICES = [
  { label: "Information Technology (IT)", value: "IT" },
  { label: "Software Development", value: "Software Development" },
  { label: "Healthcare", value: "Healthcare" },
  { label: "Finance", value: "Finance" },
  { label: "Marketing and Advertising", value: "Marketing and Advertising" },
  { label: "Education", value: "Education" },
  { label: "Engineering", value: "Engineering" },
  { label: "Sales", value: "Sales" },
  { label: "Human Resources (HR)", value: "HR" },
  { label: "Customer Service", value: "Customer Service" },
  {
    label: "Administration and Office Support",
    value: "Administration and Office Support",
  },
  { label: "Creative Arts", value: "Creative Arts" },
  {
    label: "Manufacturing and Production",
    value: "Manufacturing and Production",
  },
  { label: "Science and Research", value: "Science and Research" },
  { label: "Legal", value: "Legal" },
  { label: "Construction and Trades", value: "Construction and Trades" },
  { label: "Accounting/Taxation", value: "Accounting/Taxation" },
  { label: "Advertising/PR", value: "Advertising/PR" },
  {
    label: "Agriculture/Fertilizer/Pesticide",
    value: "Agriculture/Fertilizer/Pesticide",
  },
  { label: "Apparel/Clothing", value: "Apparel/Clothing" },
  {
    label: "Architecture/Interior Design",
    value: "Architecture/Interior Design",
  },
  { label: "Arts / Entertainment", value: "Arts/Entertainment" },
  { label: "AutoMobile", value: "AutoMobile" },
  { label: "Aviation", value: "Aviation" },
  { label: "Banking/Financial Services", value: "Banking/Financial Services" },
  { label: "BPO", value: "BPO" },
  { label: "Broadcasting", value: "Broadcasting" },
  { label: "Business Development", value: "Business Development" },
  { label: "Call Center", value: "Call Center" },
  { label: "Chemicals", value: "Chemicals" },
  { label: "Construction/Cement/Metals", value: "Construction/Cement/Metals" },
  { label: "Consultants", value: "Consultants" },
  { label: "Courier/Logistics", value: "Courier/Logistics" },
  { label: "Distribution and Logistics", value: "Distribution and Logistics" },
  { label: "Education/Training", value: "Education/Training" },
  { label: "Electronics", value: "Electronics" },
  { label: "Engineering", value: "Engineering" },
  { label: "Event Management", value: "Event Management" },
  { label: "Fashion", value: "Fashion" },
  {
    label: "Fast Moving Consumer Goods (FMCG)",
    value: "Fast Moving Consumer Goods (FMCG)",
  },
  { label: "Food & Beverages", value: "Food & Beverages" },
  { label: "Gems & Jewelery", value: "Gems & Jewelery" },
  { label: "Government", value: "Government" },
  { label: "Health & Fitness", value: "Health & Fitness" },
  {
    label: "Healthcare/Hospital/Medical",
    value: "Healthcare/Hospital/Medical",
  },
  { label: "Hospitality", value: "Hospitality" },
  {
    label: "Hotel Management / Restaurants",
    value: "Hotel Management / Restaurants",
  },
  {
    label: "Importers/ Distributors/Exporters",
    value: "Importers/ Distributors/Exporters",
  },
  { label: "Information Technology", value: "Information Technology" },
  { label: "Insurance / Takaful", value: "Insurance / Takaful" },
  { label: "Investments", value: "Investments" },
  { label: "Law Firms/Legal", value: "Law Firms/Legal" },
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Media/Communications", value: "Media/Communications" },
  { label: "Mining/Oil & Gas/Petroleum", value: "Mining/Oil & Gas/Petroleum" },
  { label: "N.G.O./Social Services", value: "N.G.O./Social Services" },
  { label: "Packaging", value: "Packaging" },
  { label: "Personal Care and Services", value: "Personal Care and Services" },
  {
    label: "Pharmaceuticals/Clinical Research",
    value: "Pharmaceuticals/Clinical Research",
  },
  { label: "Power/Energy", value: "Power/Energy" },
  { label: "Project Management", value: "Project Management" },
  { label: "Publishing/Printing", value: "Publishing/Printing" },
  { label: "Real Estate/Property", value: "Real Estate/Property" },
  {
    label: "Recruitment/Employment Firms",
    value: "Recruitment/Employment Firms",
  },
  { label: "Retail", value: "Retail" },
  { label: "Security/Law Enforcement", value: "Security/Law Enforcement" },
  { label: "Services", value: "Services" },
  { label: "Shipping/Marine", value: "Shipping/Marine" },
  { label: "Telecommunication/ISP", value: "Telecommunication/ISP" },
  { label: "Textiles/Garments", value: "Textiles/Garments" },
  { label: "Tiles & Ceramics", value: "Tiles & Ceramics" },
  {
    label: "Travel/Tourism/Transportation",
    value: "Travel/Tourism/Transportation",
  },
  { label: "Warehousing", value: "Warehousing" },
];

const SALARY_CHOICES = [
  { value: "Less than 20,000", label: "Less than 20,000" },
  { value: "20,000 - 40,000", label: "20,000 - 40,000" },
  { value: "40,000 - 60,000", label: "40,000 - 60,000" },
  { value: "60,000 - 80,000", label: "60,000 - 80,000" },
  { value: "80,000 - 100,000", label: "80,000 - 100,000" },
  { value: "100,000 - 120,000", label: "100,000 - 120,000" },
  { value: "120,000 - 150,000", label: "120,000 - 150,000" },
  { value: "150,000+", label: "150,000+" },
];

const POSITION_CHOICES = [
  { value: "Intern/Student", label: "Intern/Student" },
  { value: "Entry Level", label: "Entry Level" },
  { value: "Associate", label: "Associate" },
  { value: "Junior", label: "Junior" },
  { value: "Mid-Level", label: "Mid-Level" },
  { value: "Senior", label: "Senior" },
  { value: "Supervisor", label: "Supervisor" },
  { value: "Manager", label: "Manager" },
  { value: "Director", label: "Director" },
  { value: "Executive", label: "Executive" },
  // Add more levels and roles as needed
];
// const EDUCATION_LEVELS = [
//   { value: "No Formal Education", label: "No Formal Education" },
//   { value: "Preschool", label: "Preschool" },
//   { value: "Elementary School", label: "Elementary School" },
//   { value: "Primary School", label: "Primary School" },
//   { value: "Basic Education", label: "Basic Education" },
//   { value: "Middle School", label: "Middle School" },
//   { value: "Junior High School", label: "Junior High School" },
//   { value: "High School", label: "High School" },
//   { value: "Secondary School", label: "Secondary School" },
//   { value: "Gymnasium", label: "Gymnasium" },
//   { value: "College", label: "College" },
//   { value: "University", label: "University" },
//   { value: "Vocational School", label: "Vocational School" },
//   { value: "Technical Institute", label: "Technical Institute" },
//   { value: "Associate's Degree", label: "Associate's Degree" },
//   { value: "Bachelor's Degree", label: "Bachelor's Degree" },
//   { value: "Master's Degree", label: "Master's Degree" },
//   { value: "Doctoral Degree (Ph.D.)", label: "Doctoral Degree (Ph.D.)" },
//   { value: "Trade School", label: "Trade School" },
//   { value: "Certificate Programs", label: "Certificate Programs" },
//   { value: "Online Learning", label: "Online Learning" },
//   { value: "Lifelong Learning", label: "Lifelong Learning" },
//   { value: "Other", label: "Other" },
//   // Add more education levels as needed
// ];

const RANGE = [
  { value: "Range", label: "Range" },
  { value: "Extact Amount", label: "Extact Amount" },
];

const SALARYRATE = [
  { value: "Per_Hour", label: "Per Hour" },
  { value: "Per_Day", label: "Per Day" },
  { value: "Per_Week", label: "Per Week" },
  { value: "Per_Month", label: "Per Month" },
  { value: "Per_Year", label: "Per Year" },
];
const JOB_TYPE = [
  { value: "Full-time", label: "Full-time" },
  { value: "Part-time", label: "Part-time" },
  { value: "Temporary", label: "Temporary" },
  { value: "Contract", label: "Contract" },
  { value: "Internship", label: "Internship" },
];

// const INDUSTRY_CHOICES = [
//   { label: "Information Technology (IT)", value: "IT" },
//   { label: "Software Development", value: "Software Development" },
//   { label: "Healthcare", value: "Healthcare" },
//   { label: "Finance", value: "Finance" },
//   { label: "Marketing and Advertising", value: "Marketing and Advertising" },
//   { label: "Education", value: "Education" },
//   { label: "Engineering", value: "Engineering" },
//   { label: "Sales", value: "Sales" },
//   { label: "Human Resources (HR)", value: "HR" },
//   { label: "Customer Service", value: "Customer Service" },
//   { label: "Administration and Office Support", value: "Administration and Office Support" },
//   { label: "Creative Arts", value: "Creative Arts" },
//   { label: "Manufacturing and Production", value: "Manufacturing and Production" },
//   { label: "Science and Research", value: "Science and Research" },
//   { label: "Legal", value: "Legal" },
//   { label: "Construction and Trades", value: "Construction and Trades" },
// ];

const EDUCATION_LEVELS = [
  { label: "Non-Matriculation", value: "Non-Matriculation" },
  { label: "Matriculation/O-Level", value: "Matriculation/O-Level" },
  { label: "Intermediate/A-Level", value: "Intermediate/A-Level" },
  { label: "Bachelors", value: "Bachelors" },
  { label: "Masters", value: "Masters" },
  { label: "MPhil/MS", value: "MPhil/MS" },
  { label: "PHD/Doctorate", value: "PHD/Doctorate" },
  { label: "Certification", value: "Certification" },
  { label: "Diploma", value: "Diploma" },
  { label: "Short Course", value: "Short Course" },
  { label: "Others", value: "Others" },
];
const DEGREES_DATA = [
  { name: "Accounting", value: "Accounting" },
  { name: "Actuarial Sciences", value: "Actuarial Sciences" },
  { name: "Advertising", value: "Advertising" },
  { name: "Aerospace Engineering", value: "Aerospace Engineering" },
  { name: "Affiliate Marketing", value: "Affiliate Marketing" },
  { name: "Agile Methodology", value: "Agile Methodology" },
  { name: "Agribusiness Economics", value: "Agribusiness Economics" },
  { name: "Agricultural Economics", value: "Agricultural Economics" },
  { name: "Agricultural Extension", value: "Agricultural Extension" },
  { name: "Agriculture", value: "Agriculture" },
  { name: "Animal Science", value: "Animal Science" },
  {
    name: "Animal Science & Pre-Veterinary",
    value: "Animal Science & Pre-Veterinary",
  },
  {
    name: "Animal Science-Equine Science",
    value: "Animal Science-Equine Science",
  },
  { name: "Animal Science-Production", value: "Animal Science-Production" },
  { name: "Anthropology", value: "Anthropology" },
  { name: "Applied Anthropology", value: "Applied Anthropology" },
  { name: "Applied Science & Arts", value: "Applied Science & Arts" },
  { name: "Architectural Engineering", value: "Architectural Engineering" },
  { name: "Architectural Studies", value: "Architectural Studies" },
  { name: "Architecture", value: "Architecture" },
  { name: "Art Education", value: "Art Education" },
  { name: "Art Therapy", value: "Art Therapy" },
  { name: "Art-Art History", value: "Art-Art History" },
  { name: "Art-Ceramics", value: "Art-Ceramics" },
  { name: "Art-Communication Design", value: "Art-Communication Design" },
  { name: "Art-Drawing", value: "Art-Drawing" },
  { name: "Art-General Studio", value: "Art-General Studio" },
  { name: "Art-Glass", value: "Art-Glass" },
  { name: "Artificial Intelligence", value: "Artificial Intelligence" },
  { name: "Art-Industrial Design", value: "Art-Industrial Design" },
  { name: "Art-Metalsmithing", value: "Art-Metalsmithing" },
  { name: "Art-Painting", value: "Art-Painting" },
  { name: "Art-Printmaking", value: "Art-Printmaking" },
  { name: "Arts", value: "Arts" },
  { name: "Arts Administration", value: "Arts Administration" },
  { name: "Arts/Humanities", value: "Arts/Humanities" },
  {
    name: "Arts/Science in Counseling Psychology",
    value: "Arts/Science in Counseling Psychology",
  },
  { name: "Arts/Science in Education", value: "Arts/Science in Education" },
  { name: "Arts/Science in Teaching", value: "Arts/Science in Teaching" },
  { name: "Art-Sculpture", value: "Art-Sculpture" },
  {
    name: "Association of Chartered Certified Accountants(ACCA)",
    value: "Association of Chartered Certified Accountants(ACCA)",
  },
  { name: "Auditing", value: "Auditing" },
  { name: "Automotive Technology", value: "Automotive Technology" },
  { name: "Aviation Flight", value: "Aviation Flight" },
  { name: "Aviation Management", value: "Aviation Management" },
  { name: "Aviation Technologies", value: "Aviation Technologies" },
  {
    name: "Aviation Technologies-Aircraft Maintenance",
    value: "Aviation Technologies-Aircraft Maintenance",
  },
  {
    name: "Aviation Technologies-Aviation Electronics",
    value: "Aviation Technologies-Aviation Electronics",
  },
  {
    name: "Aviation Technologies-Helicopter Maintenance",
    value: "Aviation Technologies-Helicopter Maintenance",
  },
  {
    name: "Behavior Analysis and Therapy",
    value: "Behavior Analysis and Therapy",
  },
  { name: "Behavioral Economics", value: "Behavioral Economics" },
  { name: "Behavioral Science", value: "Behavioral Science" },
  { name: "Big Data", value: "Big Data" },
  { name: "Bioinformatics", value: "Bioinformatics" },
  { name: "Biological Sciences", value: "Biological Sciences" },
  {
    name: "Biological Sciences (Education and Human Services)",
    value: "Biological Sciences (Education and Human Services)",
  },
  {
    name: "Biological Sciences-Biomedical Science",
    value: "Biological Sciences-Biomedical Science",
  },
  { name: "Biological Sciences-Ecology", value: "Biological Sciences-Ecology" },
  { name: "Biomedical Engineering", value: "Biomedical Engineering" },
  { name: "Brand Management", value: "Brand Management" },
  { name: "Brand Strategy", value: "Brand Strategy" },
  { name: "Business", value: "Business" },
  { name: "Business Administration", value: "Business Administration" },
  {
    name: "Business Administration-Accounting",
    value: "Business Administration-Accounting",
  },
  {
    name: "Business Administration-Business Analytics",
    value: "Business Administration-Business Analytics",
  },
  {
    name: "Business Administration-Business Law",
    value: "Business Administration-Business Law",
  },
  {
    name: "Business Administration-Corporate Social Responsibility",
    value: "Business Administration-Corporate Social Responsibility",
  },
  {
    name: "Business Administration-Economics",
    value: "Business Administration-Economics",
  },
  {
    name: "Business Administration-Entrepreneurship",
    value: "Business Administration-Entrepreneurship",
  },
  {
    name: "Business Administration-Finance",
    value: "Business Administration-Finance",
  },
  {
    name: "Business Administration-Human Resources Management",
    value: "Business Administration-Human Resources Management",
  },
  {
    name: "Business Administration-Information Systems Management",
    value: "Business Administration-Information Systems Management",
  },
  {
    name: "Business Administration-International Business",
    value: "Business Administration-International Business",
  },
  {
    name: "Business Administration-Leadership",
    value: "Business Administration-Leadership",
  },
  {
    name: "Business Administration-Management",
    value: "Business Administration-Management",
  },
  {
    name: "Business Administration-Marketing",
    value: "Business Administration-Marketing",
  },
  {
    name: "Business Administration-Operations Management",
    value: "Business Administration-Operations Management",
  },
  {
    name: "Business Administration-Organizational Behavior",
    value: "Business Administration-Organizational Behavior",
  },
  {
    name: "Business Administration-Project Management",
    value: "Business Administration-Project Management",
  },
  {
    name: "Business Administration-Risk Management",
    value: "Business Administration-Risk Management",
  },
  {
    name: "Business Administration-Strategic Management",
    value: "Business Administration-Strategic Management",
  },
  {
    name: "Business Administration-Supply Chain Management",
    value: "Business Administration-Supply Chain Management",
  },
  { name: "Business Development", value: "Business Development" },
  { name: "Business Economics", value: "Business Economics" },
  { name: "Business Ethics", value: "Business Ethics" },
  { name: "Business Intelligence (BI)", value: "Business Intelligence (BI)" },
  { name: "Business Law", value: "Business Law" },
  {
    name: "Business Process Management (BPM)",
    value: "Business Process Management (BPM)",
  },
  {
    name: "Business Process Reengineering (BPR)",
    value: "Business Process Reengineering (BPR)",
  },
  { name: "Business Valuation", value: "Business Valuation" },
  { name: "Capital Budgeting", value: "Capital Budgeting" },
  { name: "Ceramic Engineering", value: "Ceramic Engineering" },
  { name: "Certified Bookkeeper (CB)", value: "Certified Bookkeeper (CB)" },
  {
    name: "Certified Fraud Examiner (CFE)",
    value: "Certified Fraud Examiner (CFE)",
  },
  {
    name: "Certified Government Financial Manager (CGFM)",
    value: "Certified Government Financial Manager (CGFM)",
  },
  {
    name: "Certified Information Systems Auditor (CISA)",
    value: "Certified Information Systems Auditor (CISA)",
  },
  {
    name: "Certified Internal Auditor (CIA)",
    value: "Certified Internal Auditor (CIA)",
  },
  {
    name: "Certified Management Accountant (CMA)",
    value: "Certified Management Accountant (CMA)",
  },
  { name: "Change Management", value: "Change Management" },
  { name: "Chartered Accountant (CA)", value: "Chartered Accountant (CA)" },
  {
    name: "Chartered Financial Analyst (CFA)",
    value: "Chartered Financial Analyst (CFA)",
  },
  { name: "Chemical Engineering", value: "Chemical Engineering" },
  { name: "Chemistry", value: "Chemistry" },
  {
    name: "Chemistry-American Chemical Society Certification",
    value: "Chemistry-American Chemical Society Certification",
  },
  { name: "Chemistry-Biochemistry", value: "Chemistry-Biochemistry" },
  { name: "Chemistry-Business", value: "Chemistry-Business" },
  {
    name: "Chemistry-Comprehensive Chemistry",
    value: "Chemistry-Comprehensive Chemistry",
  },
  {
    name: "Chemistry-Environmental Chemistry",
    value: "Chemistry-Environmental Chemistry",
  },
  {
    name: "Chemistry-Forensic Chemistry",
    value: "Chemistry-Forensic Chemistry",
  },
  { name: "Chiropractic-Pre", value: "Chiropractic-Pre" },
  { name: "Cinema & Photography", value: "Cinema & Photography" },
  { name: "Cinema & Photography-Cinema", value: "Cinema & Photography-Cinema" },
  { name: "City and Regional Planning", value: "City and Regional Planning" },
  { name: "City Planning", value: "City Planning" },
  { name: "Civil Engineering", value: "Civil Engineering" },
  {
    name: "Civil Engineering-Environmental Engineering",
    value: "Civil Engineering-Environmental Engineering",
  },
  { name: "Classics", value: "Classics" },
  { name: "Clinical Medical Science", value: "Clinical Medical Science" },
  { name: "Cloud Computing", value: "Cloud Computing" },
  {
    name: "College of Education and Human Services Undeclared",
    value: "College of Education and Human Services Undeclared",
  },
  { name: "College Teaching", value: "College Teaching" },
  { name: "Commerce", value: "Commerce" },
  { name: "Communication Disorders", value: "Communication Disorders" },
  { name: "Communication Studies", value: "Communication Studies" },
  {
    name: "Communication Studies-Intercultural Communication",
    value: "Communication Studies-Intercultural Communication",
  },
  {
    name: "Communication Studies-Interpersonal Communication",
    value: "Communication Studies-Interpersonal Communication",
  },
  {
    name: "Communication Studies-Organizational Communication",
    value: "Communication Studies-Organizational Communication",
  },
  {
    name: "Communication Studies-Performance Studies",
    value: "Communication Studies-Performance Studies",
  },
  {
    name: "Communication Studies-Persuasive Communication",
    value: "Communication Studies-Persuasive Communication",
  },
  {
    name: "Communication Studies-Public Relations",
    value: "Communication Studies-Public Relations",
  },
  { name: "Communications", value: "Communications" },
  { name: "Community Health", value: "Community Health" },
  { name: "Community Psychology", value: "Community Psychology" },
  { name: "Compensation and Benefits", value: "Compensation and Benefits" },
  { name: "Computer Engineering", value: "Computer Engineering" },
  { name: "Computer Networking", value: "Computer Networking" },
  { name: "Computer Science", value: "Computer Science" },
  { name: "Consumer Behavior", value: "Consumer Behavior" },
  { name: "Content Marketing", value: "Content Marketing" },
  { name: "Continuous Improvement", value: "Continuous Improvement" },
  { name: "Contract Management", value: "Contract Management" },
  { name: "Corporate Finance", value: "Corporate Finance" },
  { name: "Corporate Finance Law", value: "Corporate Finance Law" },
  { name: "Corporate Governance", value: "Corporate Governance" },
  { name: "Cost Accounting", value: "Cost Accounting" },
  { name: "Criminal Justice", value: "Criminal Justice" },
  {
    name: "Criminology and Criminal Justice",
    value: "Criminology and Criminal Justice",
  },
  {
    name: "Crop, Soil, and Environmental Management",
    value: "Crop, Soil, and Environmental Management",
  },
  {
    name: "Crop, Soil, and Environmental Management-Crop Production and Management[Science and General]",
    value:
      "Crop, Soil, and Environmental Management-Crop Production and Management[Science and General]",
  },
  {
    name: "Crop, Soil, and Environmental Management-Soil Science[Science and General]",
    value:
      "Crop, Soil, and Environmental Management-Soil Science[Science and General]",
  },
  { name: "Cross-Cultural Studies", value: "Cross-Cultural Studies" },
  { name: "Customer Data Management", value: "Customer Data Management" },
  { name: "Customer Experience (CX)", value: "Customer Experience (CX)" },
  {
    name: "Customer Relationship Management (CRM)",
    value: "Customer Relationship Management (CRM)",
  },
  {
    name: "Customer Relationship Management (CRM) Software",
    value: "Customer Relationship Management (CRM) Software",
  },
  { name: "Customer Service Management", value: "Customer Service Management" },
  { name: "Customs and Compliance", value: "Customs and Compliance" },
  { name: "Cybersecurity", value: "Cybersecurity" },
  { name: "Data Analytics", value: "Data Analytics" },
  { name: "Data Sciences", value: "Data Sciences" },
  { name: "Data Visualization", value: "Data Visualization" },
  { name: "Database Management", value: "Database Management" },
  { name: "Demand Forecasting", value: "Demand Forecasting" },
  { name: "Demand Planning", value: "Demand Planning" },
  { name: "Dental Hygiene", value: "Dental Hygiene" },
  { name: "Dentistry", value: "Dentistry" },
  { name: "Derivatives", value: "Derivatives" },
  { name: "Design", value: "Design" },
  { name: "Design-General Design", value: "Design-General Design" },
  { name: "Digital Marketing", value: "Digital Marketing" },
  { name: "Digital Strategy", value: "Digital Strategy" },
  { name: "Digital Transformation", value: "Digital Transformation" },
  { name: "Direct Marketing", value: "Direct Marketing" },
  { name: "Distribution Strategy", value: "Distribution Strategy" },
  { name: "Diversity and Inclusion", value: "Diversity and Inclusion" },
  { name: "Divinity", value: "Divinity" },
  { name: "Early Childhood", value: "Early Childhood" },
  {
    name: "Early Childhood-Child and Family Services",
    value: "Early Childhood-Child and Family Services",
  },
  {
    name: "Early Childhood-Preschool/Primary",
    value: "Early Childhood-Preschool/Primary",
  },
  { name: "Economics", value: "Economics" },
  {
    name: "Economics-Financial Economics",
    value: "Economics-Financial Economics",
  },
  { name: "Economics-General Economics", value: "Economics-General Economics" },
  {
    name: "Economics-International Economics",
    value: "Economics-International Economics",
  },
  { name: "Economics-Law and Economics", value: "Economics-Law and Economics" },
  { name: "Education", value: "Education" },
  {
    name: "Electrical and Computer Engineering",
    value: "Electrical and Computer Engineering",
  },
  { name: "Electrical Engineering", value: "Electrical Engineering" },
  {
    name: "Electronic Systems Technologies",
    value: "Electronic Systems Technologies",
  },
  { name: "Electronics", value: "Electronics" },
  { name: "Elementary Education", value: "Elementary Education" },
  { name: "Email Marketing", value: "Email Marketing" },
  { name: "Employee Engagement", value: "Employee Engagement" },
  { name: "Employee Relations", value: "Employee Relations" },
  { name: "Employee Wellness", value: "Employee Wellness" },
  { name: "Engineering", value: "Engineering" },
  { name: "Engineering Management", value: "Engineering Management" },
  { name: "Engineering Science", value: "Engineering Science" },
  { name: "Engineering Technology", value: "Engineering Technology" },
  {
    name: "English (Education and Human Services)",
    value: "English (Education and Human Services)",
  },
  { name: "English-Creative Writing", value: "English-Creative Writing" },
  { name: "English-General", value: "English-General" },
  { name: "English-Preprofessional", value: "English-Preprofessional" },
  { name: "English-Teacher Education", value: "English-Teacher Education" },
  { name: "Enrolled Agent (EA)", value: "Enrolled Agent (EA)" },
  {
    name: "Enterprise Resource Planning (ERP)",
    value: "Enterprise Resource Planning (ERP)",
  },
  { name: "Entrepreneurship", value: "Entrepreneurship" },
  { name: "Environmental Design", value: "Environmental Design" },
  { name: "Environmental Engineering", value: "Environmental Engineering" },
  { name: "Environmental Planning", value: "Environmental Planning" },
  { name: "Environmental Science", value: "Environmental Science" },
  { name: "Event Marketing", value: "Event Marketing" },
  { name: "Exercise and Sport Studies", value: "Exercise and Sport Studies" },
  { name: "Exercise Science", value: "Exercise Science" },
  { name: "Experiential Marketing", value: "Experiential Marketing" },
  { name: "Exploratory (Undecided)", value: "Exploratory (Undecided)" },
  { name: "Family Counseling", value: "Family Counseling" },
  { name: "Family Therapy", value: "Family Therapy" },
  {
    name: "Fashion Design and Merchandising",
    value: "Fashion Design and Merchandising",
  },
  {
    name: "Fashion Design and Merchandising-Fashion Stylist",
    value: "Fashion Design and Merchandising-Fashion Stylist",
  },
  {
    name: "Fashion Design Merchandising-Fashion Design",
    value: "Fashion Design Merchandising-Fashion Design",
  },
  { name: "Fermentation Science", value: "Fermentation Science" },
  { name: "Finance", value: "Finance" },
  {
    name: "Finance-Financial Institutions",
    value: "Finance-Financial Institutions",
  },
  {
    name: "Finance-Financial Management",
    value: "Finance-Financial Management",
  },
  { name: "Finance-Investments", value: "Finance-Investments" },
  { name: "Financial Accounting", value: "Financial Accounting" },
  { name: "Financial Analysis", value: "Financial Analysis" },
  { name: "Financial Planning", value: "Financial Planning" },
  { name: "Financial Risk Management", value: "Financial Risk Management" },
  { name: "Fine Arts", value: "Fine Arts" },
  { name: "Fleet Management", value: "Fleet Management" },
  {
    name: "Foreign Languages and Literatures",
    value: "Foreign Languages and Literatures",
  },
  { name: "Forensic Accounting", value: "Forensic Accounting" },
  { name: "Forensic Science", value: "Forensic Science" },
  { name: "Forestry", value: "Forestry" },
  { name: "Forestry-Forest Hydrology", value: "Forestry-Forest Hydrology" },
  {
    name: "Forestry-Forest Recreation and Park Management",
    value: "Forestry-Forest Recreation and Park Management",
  },
  {
    name: "Forestry-Forest Resources Management",
    value: "Forestry-Forest Resources Management",
  },
  {
    name: "Forestry-Urban Forest Management",
    value: "Forestry-Urban Forest Management",
  },
  {
    name: "Forestry-Wildlife Habitat Management and Conservation",
    value: "Forestry-Wildlife Habitat Management and Conservation",
  },
  {
    name: "French (Education and Human Services)",
    value: "French (Education and Human Services)",
  },
  { name: "Genetic Counseling", value: "Genetic Counseling" },
  {
    name: "Geography & Environmental Resources",
    value: "Geography & Environmental Resources",
  },
  { name: "Geological Engineering", value: "Geological Engineering" },
  { name: "Geology", value: "Geology" },
  {
    name: "Geology-Environmental Geology",
    value: "Geology-Environmental Geology",
  },
  { name: "Geology-Geophysics", value: "Geology-Geophysics" },
  { name: "Geology-Resource Geology", value: "Geology-Resource Geology" },
  {
    name: "German Studies(Education and Human Services)",
    value: "German Studies(Education and Human Services)",
  },
  { name: "Gerontology", value: "Gerontology" },
  { name: "Guerrilla Marketing", value: "Guerrilla Marketing" },
  { name: "Health Administration", value: "Health Administration" },
  { name: "Health Care Administration", value: "Health Care Administration" },
  { name: "Health Care Management", value: "Health Care Management" },
  {
    name: "Heritage/Historical Preservation",
    value: "Heritage/Historical Preservation",
  },
  { name: "History", value: "History" },
  {
    name: "History (Education and Human Services)",
    value: "History (Education and Human Services)",
  },
  { name: "History-History Honors", value: "History-History Honors" },
  { name: "Home Economics", value: "Home Economics" },
  { name: "Horticulture", value: "Horticulture" },
  {
    name: "Horticulture-Landscape Horticulture",
    value: "Horticulture-Landscape Horticulture",
  },
  {
    name: "Horticulture-Production Horticulture",
    value: "Horticulture-Production Horticulture",
  },
  {
    name: "Horticulture-Turfgrass Management",
    value: "Horticulture-Turfgrass Management",
  },
  {
    name: "Hospitality & Tourism Administration",
    value: "Hospitality & Tourism Administration",
  },
  { name: "Hospitality Management", value: "Hospitality Management" },
  { name: "Human Nutrition & Dietetics", value: "Human Nutrition & Dietetics" },
  { name: "Human Resource Development", value: "Human Resource Development" },
  { name: "Human Resources", value: "Human Resources" },
  {
    name: "Human Resources Management (HRM)",
    value: "Human Resources Management (HRM)",
  },
  {
    name: "Individualized/Interdisciplinary Studies",
    value: "Individualized/Interdisciplinary Studies",
  },
  { name: "Industrial Design", value: "Industrial Design" },
  { name: "Industrial Engineering", value: "Industrial Engineering" },
  {
    name: "Industrial Management and Applied Engineering-Qualtiy Management",
    value: "Industrial Management and Applied Engineering-Qualtiy Management",
  },
  { name: "Industrial Organization", value: "Industrial Organization" },
  { name: "Industrial Relations", value: "Industrial Relations" },
  { name: "Influencer Marketing", value: "Influencer Marketing" },
  {
    name: "Information Resources Management",
    value: "Information Resources Management",
  },
  { name: "Information Science", value: "Information Science" },
  {
    name: "Information Systems Management",
    value: "Information Systems Management",
  },
  {
    name: "Information Systems Technologies",
    value: "Information Systems Technologies",
  },
  { name: "Information Technology", value: "Information Technology" },
  {
    name: "Information Technology Governance",
    value: "Information Technology Governance",
  },
  { name: "Innovation Management", value: "Innovation Management" },
  { name: "Interior Design", value: "Interior Design" },
  { name: "International Affairs", value: "International Affairs" },
  { name: "International Business", value: "International Business" },
  { name: "International Logistics", value: "International Logistics" },
  { name: "International Marketing", value: "International Marketing" },
  { name: "International Studies", value: "International Studies" },
  { name: "Inventory Control", value: "Inventory Control" },
  { name: "Inventory Management", value: "Inventory Management" },
  { name: "Investment Management", value: "Investment Management" },
  { name: "IT Project Management", value: "IT Project Management" },
  { name: "IT Service Management", value: "IT Service Management" },
  { name: "Journalism", value: "Journalism" },
  { name: "Journalism-Advertising", value: "Journalism-Advertising" },
  {
    name: "Journalism-New Media News Production",
    value: "Journalism-New Media News Production",
  },
  { name: "Journalism-News-Editorial", value: "Journalism-News-Editorial" },
  { name: "Journalism-Photojournalism", value: "Journalism-Photojournalism" },
  { name: "Journalism-Sports Media", value: "Journalism-Sports Media" },
  { name: "Justice Administration", value: "Justice Administration" },
  { name: "Just-in-Time (JIT)", value: "Just-in-Time (JIT)" },
  { name: "Knowledge Management", value: "Knowledge Management" },
  { name: "Labor Relations", value: "Labor Relations" },
  { name: "Landscape Architecture", value: "Landscape Architecture" },
  {
    name: "Languages, Cultures, and International Studies",
    value: "Languages, Cultures, and International Studies",
  },
  { name: "Law Librarianship", value: "Law Librarianship" },
  { name: "Law-Pre", value: "Law-Pre" },
  { name: "Laws", value: "Laws" },
  { name: "Lean Management", value: "Lean Management" },
  { name: "Liberal Arts", value: "Liberal Arts" },
  { name: "Liberal Studies", value: "Liberal Studies" },
  { name: "Library Science", value: "Library Science" },
  { name: "Linguistics", value: "Linguistics" },
  {
    name: "Linguistics-English as a New Language",
    value: "Linguistics-English as a New Language",
  },
  { name: "Logistics Management", value: "Logistics Management" },
  { name: "M.B.B.S.", value: "M.B.B.S." },
  { name: "Machine Learning", value: "Machine Learning" },
  { name: "Macroeconomics", value: "Macroeconomics" },
  { name: "Management", value: "Management" },
  { name: "Management -Marketing", value: "Management -Marketing" },
  { name: "Management-Entrepreneurship", value: "Management-Entrepreneurship" },
  { name: "Management-Finance", value: "Management-Finance" },
  {
    name: "Management-Global E-Business",
    value: "Management-Global E-Business",
  },
  {
    name: "Management-Management of Health Care Enterprises",
    value: "Management-Management of Health Care Enterprises",
  },
  {
    name: "Management-Personnel Management",
    value: "Management-Personnel Management",
  },
  {
    name: "Management-Supply Chain Management",
    value: "Management-Supply Chain Management",
  },
  { name: "Managerial Accounting", value: "Managerial Accounting" },
  { name: "Managerial Economics", value: "Managerial Economics" },
  { name: "Manufacturing Engineering", value: "Manufacturing Engineering" },
  { name: "Manufacturing Management", value: "Manufacturing Management" },
  { name: "Marine Affairs", value: "Marine Affairs" },
  { name: "Market Research", value: "Market Research" },
  { name: "Market Segmentation", value: "Market Segmentation" },
  { name: "Marketing", value: "Marketing" },
  { name: "Marketing Analytics", value: "Marketing Analytics" },
  { name: "Marketing Automation", value: "Marketing Automation" },
  { name: "Marketing Strategy", value: "Marketing Strategy" },
  { name: "Mass Communication", value: "Mass Communication" },
  {
    name: "Mass Communication and Media Arts",
    value: "Mass Communication and Media Arts",
  },
  { name: "Materials Management", value: "Materials Management" },
  { name: "Materials Science", value: "Materials Science" },
  { name: "Mathematics", value: "Mathematics" },
  { name: "Mathematics & Statistics", value: "Mathematics & Statistics" },
  {
    name: "Mathematics (Education and Human Services)",
    value: "Mathematics (Education and Human Services)",
  },
  { name: "Mathematics (Liberal Arts)", value: "Mathematics (Liberal Arts)" },
  { name: "Mechanical Engineering", value: "Mechanical Engineering" },
  {
    name: "Medical Education Preparation",
    value: "Medical Education Preparation",
  },
  { name: "Medical Illustration", value: "Medical Illustration" },
  { name: "Medical Technology", value: "Medical Technology" },
  { name: "Medicine-Pre", value: "Medicine-Pre" },
  {
    name: "Mergers and Acquisitions (M&A)",
    value: "Mergers and Acquisitions (M&A)",
  },
  { name: "Metallurgical Engineering", value: "Metallurgical Engineering" },
  { name: "Microbiology", value: "Microbiology" },
  { name: "Microeconomics", value: "Microeconomics" },
  { name: "Mining Engineering", value: "Mining Engineering" },
  { name: "Mobile App Development", value: "Mobile App Development" },
  { name: "Mobile Marketing", value: "Mobile Marketing" },
  {
    name: "Molecular, Cellular and Systemic Physiology",
    value: "Molecular, Cellular and Systemic Physiology",
  },
  {
    name: "Mortuary Science & Funeral Service",
    value: "Mortuary Science & Funeral Service",
  },
  { name: "Music", value: "Music" },
  { name: "Music Education", value: "Music Education" },
  { name: "Music Therapy", value: "Music Therapy" },
  { name: "Natural Science", value: "Natural Science" },
  { name: "Network Administration", value: "Network Administration" },
  { name: "Nuclear Engineering", value: "Nuclear Engineering" },
  { name: "Nurse Anesthesia", value: "Nurse Anesthesia" },
  { name: "Nursing", value: "Nursing" },
  { name: "Nursing Administration", value: "Nursing Administration" },
  {
    name: "Nursing-Pre, Pre-Professional",
    value: "Nursing-Pre, Pre-Professional",
  },
  {
    name: "Occupational Safety and Health",
    value: "Occupational Safety and Health",
  },
  { name: "Occupational Therapy", value: "Occupational Therapy" },
  { name: "Operations Management", value: "Operations Management" },
  { name: "Optometry-Pre", value: "Optometry-Pre" },
  { name: "Organizational Behavior", value: "Organizational Behavior" },
  { name: "Organizational Development", value: "Organizational Development" },
  { name: "Orthodontics", value: "Orthodontics" },
  { name: "Paralegal Studies", value: "Paralegal Studies" },
  { name: "Paralegal Studies-Pre-Law", value: "Paralegal Studies-Pre-Law" },
  { name: "Pathology", value: "Pathology" },
  {
    name: "Pay-Per-Click (PPC) Advertising",
    value: "Pay-Per-Click (PPC) Advertising",
  },
  { name: "Performance Management", value: "Performance Management" },
  { name: "Petroleum Engineering", value: "Petroleum Engineering" },
  { name: "Pharmaceutical Sciences", value: "Pharmaceutical Sciences" },
  { name: "Pharmacy", value: "Pharmacy" },
  { name: "Philosophy", value: "Philosophy" },
  { name: "Philosophy-Pre Law", value: "Philosophy-Pre Law" },
  {
    name: "Photography (Cinema & Photography)",
    value: "Photography (Cinema & Photography)",
  },
  { name: "Physical Education", value: "Physical Education" },
  {
    name: "Physical Education Teacher Education",
    value: "Physical Education Teacher Education",
  },
  {
    name: "Physical Therapist Assistant",
    value: "Physical Therapist Assistant",
  },
  { name: "Physical Therapy", value: "Physical Therapy" },
  { name: "Physician Assistant-Pre", value: "Physician Assistant-Pre" },
  { name: "Physics", value: "Physics" },
  { name: "Physics-Biomedical Physics", value: "Physics-Biomedical Physics" },
  {
    name: "Physics-Computational Physics",
    value: "Physics-Computational Physics",
  },
  {
    name: "Physics-Materials and Nanophysics",
    value: "Physics-Materials and Nanophysics",
  },
  { name: "Physiology", value: "Physiology" },
  { name: "Planning", value: "Planning" },
  { name: "Plant Biology", value: "Plant Biology" },
  {
    name: "Plant Biology-Molecular and Biochemical Physiology",
    value: "Plant Biology-Molecular and Biochemical Physiology",
  },
  {
    name: "Plant Biology-Systematics and Biodiversity",
    value: "Plant Biology-Systematics and Biodiversity",
  },
  { name: "Podiatry-Pre", value: "Podiatry-Pre" },
  { name: "Political Science", value: "Political Science" },
  {
    name: "Political Science-International Affairs",
    value: "Political Science-International Affairs",
  },
  {
    name: "Political Science-Public Service",
    value: "Political Science-Public Service",
  },
  { name: "Portfolio Management", value: "Portfolio Management" },
  { name: "Pricing Strategy", value: "Pricing Strategy" },
  { name: "Private Equity", value: "Private Equity" },
  { name: "Procurement Management", value: "Procurement Management" },
  { name: "Product Development", value: "Product Development" },
  { name: "Product Management", value: "Product Management" },
  { name: "Professional Accounting", value: "Professional Accounting" },
  { name: "Project Management", value: "Project Management" },
  { name: "Psycholgy-Parent Training", value: "Psycholgy-Parent Training" },
  { name: "Psychology", value: "Psychology" },
  { name: "Public Administration", value: "Public Administration" },
  {
    name: "Public and International Affairs",
    value: "Public and International Affairs",
  },
  { name: "Public Health", value: "Public Health" },
  { name: "Public Health Education", value: "Public Health Education" },
  { name: "Public Management", value: "Public Management" },
  { name: "Public Policy", value: "Public Policy" },
  { name: "Public Relations (PR)", value: "Public Relations (PR)" },
  { name: "Public Safety Management", value: "Public Safety Management" },
  { name: "Quality Assurance", value: "Quality Assurance" },
  { name: "Quality Management", value: "Quality Management" },
  {
    name: "Radio Television and Digital Media-Electronic Sports Media",
    value: "Radio Television and Digital Media-Electronic Sports Media",
  },
  {
    name: "Radio, Television, and Digital Media",
    value: "Radio, Television, and Digital Media",
  },
  {
    name: "Radio-Frequency Identification (RFID)",
    value: "Radio-Frequency Identification (RFID)",
  },
  { name: "Radiologic Sciences", value: "Radiologic Sciences" },
  {
    name: "Radiologic Sciences-Diagnostic Medical Ultrasound",
    value: "Radiologic Sciences-Diagnostic Medical Ultrasound",
  },
  {
    name: "Radiologic Sciences-Magnetic Resonance Imaging/Computed",
    value: "Radiologic Sciences-Magnetic Resonance Imaging/Computed",
  },
  {
    name: "Radiologic Sciences-Magnetic Resonance Imaging/Computed Tomography",
    value: "Radiologic Sciences-Magnetic Resonance Imaging/Computed Tomography",
  },
  {
    name: "Radiologic Sciences-Radiation Therapy Technology",
    value: "Radiologic Sciences-Radiation Therapy Technology",
  },
  {
    name: "Radiologic Sciences-Radiology Education and Management",
    value: "Radiologic Sciences-Radiology Education and Management",
  },
  { name: "Real Estate Development", value: "Real Estate Development" },
  { name: "Recreation", value: "Recreation" },
  { name: "Recreation Administration", value: "Recreation Administration" },
  {
    name: "Recreation and Parks Administration",
    value: "Recreation and Parks Administration",
  },
  {
    name: "Recreation-Leisure Services Management",
    value: "Recreation-Leisure Services Management",
  },
  {
    name: "Recreation-Outdoor Recreation Leadership and Management",
    value: "Recreation-Outdoor Recreation Leadership and Management",
  },
  {
    name: "Recreation-Therapeutic Recreation",
    value: "Recreation-Therapeutic Recreation",
  },
  { name: "Regional Planning", value: "Regional Planning" },
  { name: "Rehabilitation Counseling", value: "Rehabilitation Counseling" },
  { name: "Rehabilitation Medicine", value: "Rehabilitation Medicine" },
  { name: "Rehabilitation Services", value: "Rehabilitation Services" },
  { name: "Relationship Marketing", value: "Relationship Marketing" },
  { name: "Religious Education", value: "Religious Education" },
  { name: "Retail Management", value: "Retail Management" },
  { name: "Retail Strategy", value: "Retail Strategy" },
  { name: "Reverse Logistics", value: "Reverse Logistics" },
  { name: "Risk Management", value: "Risk Management" },
  { name: "Sales Management", value: "Sales Management" },
  { name: "Science", value: "Science" },
  { name: "Scrum", value: "Scrum" },
  {
    name: "Search Engine Optimization (SEO)",
    value: "Search Engine Optimization (SEO)",
  },
  { name: "Service Design", value: "Service Design" },
  { name: "Service Management", value: "Service Management" },
  { name: "Services Administration", value: "Services Administration" },
  { name: "Six Sigma", value: "Six Sigma" },
  { name: "Social Media Management", value: "Social Media Management" },
  { name: "Social Media Marketing", value: "Social Media Marketing" },
  { name: "Social Work", value: "Social Work" },
  { name: "Sociology", value: "Sociology" },
  { name: "Software Development", value: "Software Development" },
  { name: "Software Engineering", value: "Software Engineering" },
  { name: "Sourcing Strategy", value: "Sourcing Strategy" },
  { name: "Special Education", value: "Special Education" },
  { name: "Sport Administration", value: "Sport Administration" },
  { name: "Strategic Management", value: "Strategic Management" },
  {
    name: "Supplier Relationship Management",
    value: "Supplier Relationship Management",
  },
  { name: "Supply Chain Analytics", value: "Supply Chain Analytics" },
  { name: "Supply Chain Management", value: "Supply Chain Management" },
  { name: "Systems Analysis", value: "Systems Analysis" },
  { name: "Talent Acquisition", value: "Talent Acquisition" },
  { name: "Taxation", value: "Taxation" },
  {
    name: "Technical Resource Management",
    value: "Technical Resource Management",
  },
  {
    name: "Technical Resource Management-Health and Safety Management",
    value: "Technical Resource Management-Health and Safety Management",
  },
  {
    name: "Technical Resource Management-Organizational Development",
    value: "Technical Resource Management-Organizational Development",
  },
  { name: "Technology", value: "Technology" },
  { name: "Textiles", value: "Textiles" },
  { name: "The Humanities", value: "The Humanities" },
  { name: "Theater", value: "Theater" },
  { name: "Theology", value: "Theology" },
  {
    name: "Total Quality Management (TQM)",
    value: "Total Quality Management (TQM)",
  },
  { name: "Tourism Management", value: "Tourism Management" },
  { name: "Trade Marketing", value: "Trade Marketing" },
  { name: "Training and Development", value: "Training and Development" },
  { name: "Transportation Management", value: "Transportation Management" },
  { name: "University Studies", value: "University Studies" },
  { name: "Urban Planning", value: "Urban Planning" },
  { name: "Urban Studies", value: "Urban Studies" },
  { name: "User Experience (UX)", value: "User Experience (UX)" },
  { name: "User Interface (UI)", value: "User Interface (UI)" },
  { name: "Venture Capital", value: "Venture Capital" },
  { name: "Veterinary Medicine-Pre", value: "Veterinary Medicine-Pre" },
  { name: "Veterinary Science", value: "Veterinary Science" },
  {
    name: "Warehouse Management Systems (WMS)",
    value: "Warehouse Management Systems (WMS)",
  },
  {
    name: "Warehousing and Distribution",
    value: "Warehousing and Distribution",
  },
  { name: "Web Development", value: "Web Development" },
  { name: "Women’s Studies", value: "Women’s Studies" },
  {
    name: "Workforce Education & Development",
    value: "Workforce Education & Development",
  },
  { name: "Workforce Planning", value: "Workforce Planning" },
  { name: "Zoology", value: "Zoology" },
  { name: "Zoology-Animal Biology", value: "Zoology-Animal Biology" },
  {
    name: "Zoology-Environmental Biology",
    value: "Zoology-Environmental Biology",
  },
  { name: "Zoology-Fisheries Biology", value: "Zoology-Fisheries Biology" },
  {
    name: "Zoology-Pre-Veterinary Medicine",
    value: "Zoology-Pre-Veterinary Medicine",
  },
  { name: "Zoology-Wildlife Biology", value: "Zoology-Wildlife Biology" },
];
const CURRENCY=[
  {
    label: "AFN (؋)",
    value: "AFN (؋)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "ALL (Lek)",
    value: "ALL (Lek)"
  },
  {
    label: "DZD (دج)",
    value: "DZD (دج)"
  },
  {
    label: "USD ($)",
    value: "USD ($)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "AOA (Kz)",
    value: "AOA (Kz)"
  },
  {
    label: "XCD ($)",
    value: "XCD ($)"
  },
  {
    label: "AAD ($)",
    value: "AAD ($)"
  },
  {
    label: "XCD ($)",
    value: "XCD ($)"
  },
  {
    label: "ARS ($)",
    value: "ARS ($)"
  },
  {
    label: "AMD (֏)",
    value: "AMD (֏)"
  },
  {
    label: "AWG (ƒ)",
    value: "AWG (ƒ)"
  },
  {
    label: "AUD ($)",
    value: "AUD ($)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "AZN (m)",
    value: "AZN (m)"
  },
  {
    label: "BHD (.د.ب)",
    value: "BHD (.د.ب)"
  },
  {
    label: "BDT (৳)",
    value: "BDT (৳)"
  },
  {
    label: "BBD (Bds$)",
    value: "BBD (Bds$)"
  },
  {
    label: "BYN (Br)",
    value: "BYN (Br)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "BZD ($)",
    value: "BZD ($)"
  },
  {
    label: "XOF (CFA)",
    value: "XOF (CFA)"
  },
  {
    label: "BMD ($)",
    value: "BMD ($)"
  },
  {
    label: "BTN (Nu.)",
    value: "BTN (Nu.)"
  },
  {
    label: "BOB (Bs.)",
    value: "BOB (Bs.)"
  },
  {
    label: "USD ($)",
    value: "USD ($)"
  },
  {
    label: "BAM (KM)",
    value: "BAM (KM)"
  },
  {
    label: "BWP (P)",
    value: "BWP (P)"
  },
  {
    label: "NOK (kr)",
    value: "NOK (kr)"
  },
  {
    label: "BRL (R$)",
    value: "BRL (R$)"
  },
  {
    label: "USD ($)",
    value: "USD ($)"
  },
  {
    label: "BND (B$)",
    value: "BND (B$)"
  },
  {
    label: "BGN (Лв.)",
    value: "BGN (Лв.)"
  },
  {
    label: "XOF (CFA)",
    value: "XOF (CFA)"
  },
  {
    label: "BIF (FBu)",
    value: "BIF (FBu)"
  },
  {
    label: "KHR (KHR)",
    value: "KHR (KHR)"
  },
  {
    label: "XAF (FCFA)",
    value: "XAF (FCFA)"
  },
  {
    label: "CAD ($)",
    value: "CAD ($)"
  },
  {
    label: "CVE ($)",
    value: "CVE ($)"
  },
  {
    label: "KYD ($)",
    value: "KYD ($)"
  },
  {
    label: "XAF (FCFA)",
    value: "XAF (FCFA)"
  },
  {
    label: "XAF (FCFA)",
    value: "XAF (FCFA)"
  },
  {
    label: "CLP ($)",
    value: "CLP ($)"
  },
  {
    label: "CNY (¥)",
    value: "CNY (¥)"
  },
  {
    label: "AUD ($)",
    value: "AUD ($)"
  },
  {
    label: "AUD ($)",
    value: "AUD ($)"
  },
  {
    label: "COP ($)",
    value: "COP ($)"
  },
  {
    label: "KMF (CF)",
    value: "KMF (CF)"
  },
  {
    label: "XAF (FC)",
    value: "XAF (FC)"
  },
  {
    label: "NZD ($)",
    value: "NZD ($)"
  },
  {
    label: "CRC (₡)",
    value: "CRC (₡)"
  },
  {
    label: "XOF (CFA)",
    value: "XOF (CFA)"
  },
  {
    label: "HRK (kn)",
    value: "HRK (kn)"
  },
  {
    label: "CUP ($)",
    value: "CUP ($)"
  },
  {
    label: "ANG (ƒ)",
    value: "ANG (ƒ)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "CZK (Kč)",
    value: "CZK (Kč)"
  },
  {
    label: "CDF (FC)",
    value: "CDF (FC)"
  },
  {
    label: "DKK (Kr.)",
    value: "DKK (Kr.)"
  },
  {
    label: "DJF (Fdj)",
    value: "DJF (Fdj)"
  },
  {
    label: "XCD ($)",
    value: "XCD ($)"
  },
  {
    label: "DOP ($)",
    value: "DOP ($)"
  },
  {
    label: "USD ($)",
    value: "USD ($)"
  },
  {
    label: "USD ($)",
    value: "USD ($)"
  },
  {
    label: "EGP (ج.م)",
    value: "EGP (ج.م)"
  },
  {
    label: "USD ($)",
    value: "USD ($)"
  },
  {
    label: "XAF (FCFA)",
    value: "XAF (FCFA)"
  },
  {
    label: "ERN (Nfk)",
    value: "ERN (Nfk)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "ETB (Nkf)",
    value: "ETB (Nkf)"
  },
  {
    label: "FKP (£)",
    value: "FKP (£)"
  },
  {
    label: "DKK (Kr.)",
    value: "DKK (Kr.)"
  },
  {
    label: "FJD (FJ$)",
    value: "FJD (FJ$)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "XPF (₣)",
    value: "XPF (₣)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "XAF (FCFA)",
    value: "XAF (FCFA)"
  },
  {
    label: "GMD (D)",
    value: "GMD (D)"
  },
  {
    label: "GEL (ლ)",
    value: "GEL (ლ)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "GHS (GH₵)",
    value: "GHS (GH₵)"
  },
  {
    label: "GIP (£)",
    value: "GIP (£)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "DKK (Kr.)",
    value: "DKK (Kr.)"
  },
  {
    label: "XCD ($)",
    value: "XCD ($)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "USD ($)",
    value: "USD ($)"
  },
  {
    label: "GTQ (Q)",
    value: "GTQ (Q)"
  },
  {
    label: "GBP (£)",
    value: "GBP (£)"
  },
  {
    label: "GNF (FG)",
    value: "GNF (FG)"
  },
  {
    label: "XOF (CFA)",
    value: "XOF (CFA)"
  },
  {
    label: "GYD ($)",
    value: "GYD ($)"
  },
  {
    label: "HTG (G)",
    value: "HTG (G)"
  },
  {
    label: "AUD ($)",
    value: "AUD ($)"
  },
  {
    label: "HNL (L)",
    value: "HNL (L)"
  },
  {
    label: "HKD ($)",
    value: "HKD ($)"
  },
  {
    label: "HUF (Ft)",
    value: "HUF (Ft)"
  },
  {
    label: "ISK (kr)",
    value: "ISK (kr)"
  },
  {
    label: "INR (₹)",
    value: "INR (₹)"
  },
  {
    label: "IDR (Rp)",
    value: "IDR (Rp)"
  },
  {
    label: "IRR (﷼)",
    value: "IRR (﷼)"
  },
  {
    label: "IQD (د.ع)",
    value: "IQD (د.ع)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "ILS (₪)",
    value: "ILS (₪)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "JMD (J$)",
    value: "JMD (J$)"
  },
  {
    label: "JPY (¥)",
    value: "JPY (¥)"
  },
  {
    label: "GBP (£)",
    value: "GBP (£)"
  },
  {
    label: "JOD (ا.د)",
    value: "JOD (ا.د)"
  },
  {
    label: "KZT (лв)",
    value: "KZT (лв)"
  },
  {
    label: "KES (KSh)",
    value: "KES (KSh)"
  },
  {
    label: "AUD ($)",
    value: "AUD ($)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "KWD (ك.د)",
    value: "KWD (ك.د)"
  },
  {
    label: "KGS (лв)",
    value: "KGS (лв)"
  },
  {
    label: "LAK (₭)",
    value: "LAK (₭)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "LBP (£)",
    value: "LBP (£)"
  },
  {
    label: "LSL (L)",
    value: "LSL (L)"
  },
  {
    label: "LRD ($)",
    value: "LRD ($)"
  },
  {
    label: "LYD (د.ل)",
    value: "LYD (د.ل)"
  },
  {
    label: "CHF (CHf)",
    value: "CHF (CHf)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "MOP ($)",
    value: "MOP ($)"
  },
  {
    label: "MGA (Ar)",
    value: "MGA (Ar)"
  },
  {
    label: "MWK (MK)",
    value: "MWK (MK)"
  },
  {
    label: "MYR (RM)",
    value: "MYR (RM)"
  },
  {
    label: "MVR (Rf)",
    value: "MVR (Rf)"
  },
  {
    label: "XOF (CFA)",
    value: "XOF (CFA)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "GBP (£)",
    value: "GBP (£)"
  },
  {
    label: "USD ($)",
    value: "USD ($)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "MRO (MRU)",
    value: "MRO (MRU)"
  },
  {
    label: "MUR (₨)",
    value: "MUR (₨)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "MXN ($)",
    value: "MXN ($)"
  },
  {
    label: "USD ($)",
    value: "USD ($)"
  },
  {
    label: "MDL (L)",
    value: "MDL (L)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "MNT (₮)",
    value: "MNT (₮)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "XCD ($)",
    value: "XCD ($)"
  },
  {
    label: "MAD (DH)",
    value: "MAD (DH)"
  },
  {
    label: "MZN (MT)",
    value: "MZN (MT)"
  },
  {
    label: "MMK (K)",
    value: "MMK (K)"
  },
  {
    label: "NAD ($)",
    value: "NAD ($)"
  },
  {
    label: "AUD ($)",
    value: "AUD ($)"
  },
  {
    label: "NPR (₨)",
    value: "NPR (₨)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "XPF (₣)",
    value: "XPF (₣)"
  },
  {
    label: "NZD ($)",
    value: "NZD ($)"
  },
  {
    label: "NIO (C$)",
    value: "NIO (C$)"
  },
  {
    label: "XOF (CFA)",
    value: "XOF (CFA)"
  },
  {
    label: "NGN (₦)",
    value: "NGN (₦)"
  },
  {
    label: "NZD ($)",
    value: "NZD ($)"
  },
  {
    label: "AUD ($)",
    value: "AUD ($)"
  },
  {
    label: "KPW (₩)",
    value: "KPW (₩)"
  },
  {
    label: "MKD (ден)",
    value: "MKD (ден)"
  },
  {
    label: "USD ($)",
    value: "USD ($)"
  },
  {
    label: "NOK (kr)",
    value: "NOK (kr)"
  },
  {
    label: "OMR (.ع.ر)",
    value: "OMR (.ع.ر)"
  },
  {
    label: "PKR (₨)",
    value: "PKR (₨)"
  },
  {
    label: "USD ($)",
    value: "USD ($)"
  },
  {
    label: "ILS (₪)",
    value: "ILS (₪)"
  },
  {
    label: "PAB (B/.)",
    value: "PAB (B/.)"
  },
  {
    label: "PGK (K)",
    value: "PGK (K)"
  },
  {
    label: "PYG (₲)",
    value: "PYG (₲)"
  },
  {
    label: "PEN (S/.)",
    value: "PEN (S/.)"
  },
  {
    label: "PHP (₱)",
    value: "PHP (₱)"
  },
  {
    label: "NZD ($)",
    value: "NZD ($)"
  },
  {
    label: "PLN (zł)",
    value: "PLN (zł)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "USD ($)",
    value: "USD ($)"
  },
  {
    label: "QAR (ق.ر)",
    value: "QAR (ق.ر)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "RON (lei)",
    value: "RON (lei)"
  },
  {
    label: "RUB (₽)",
    value: "RUB (₽)"
  },
  {
    label: "RWF (FRw)",
    value: "RWF (FRw)"
  },
  {
    label: "SHP (£)",
    value: "SHP (£)"
  },
  {
    label: "XCD ($)",
    value: "XCD ($)"
  },
  {
    label: "XCD ($)",
    value: "XCD ($)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "XCD ($)",
    value: "XCD ($)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "WST (SAT)",
    value: "WST (SAT)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "STD (Db)",
    value: "STD (Db)"
  },
  {
    label: "SAR (﷼)",
    value: "SAR (﷼)"
  },
  {
    label: "XOF (CFA)",
    value: "XOF (CFA)"
  },
  {
    label: "RSD (din)",
    value: "RSD (din)"
  },
  {
    label: "SCR (SRe)",
    value: "SCR (SRe)"
  },
  {
    label: "SLL (Le)",
    value: "SLL (Le)"
  },
  {
    label: "SGD ($)",
    value: "SGD ($)"
  },
  {
    label: "ANG (ƒ)",
    value: "ANG (ƒ)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "SBD (Si$)",
    value: "SBD (Si$)"
  },
  {
    label: "SOS (Sh.so.)",
    value: "SOS (Sh.so.)"
  },
  {
    label: "ZAR (R)",
    value: "ZAR (R)"
  },
  {
    label: "GBP (£)",
    value: "GBP (£)"
  },
  {
    label: "KRW (₩)",
    value: "KRW (₩)"
  },
  {
    label: "SSP (£)",
    value: "SSP (£)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "LKR (Rs)",
    value: "LKR (Rs)"
  },
  {
    label: "SDG (.س.ج)",
    value: "SDG (.س.ج)"
  },
  {
    label: "SRD ($)",
    value: "SRD ($)"
  },
  {
    label: "NOK (kr)",
    value: "NOK (kr)"
  },
  {
    label: "SZL (E)",
    value: "SZL (E)"
  },
  {
    label: "SEK (kr)",
    value: "SEK (kr)"
  },
  {
    label: "CHF (CHf)",
    value: "CHF (CHf)"
  },
  {
    label: "SYP (LS)",
    value: "SYP (LS)"
  },
  {
    label: "TWD ($)",
    value: "TWD ($)"
  },
  {
    label: "TJS (SM)",
    value: "TJS (SM)"
  },
  {
    label: "TZS (TSh)",
    value: "TZS (TSh)"
  },
  {
    label: "THB (฿)",
    value: "THB (฿)"
  },
  {
    label: "BSD (B$)",
    value: "BSD (B$)"
  },
  {
    label: "XOF (CFA)",
    value: "XOF (CFA)"
  },
  {
    label: "NZD ($)",
    value: "NZD ($)"
  },
  {
    label: "TOP ($)",
    value: "TOP ($)"
  },
  {
    label: "TTD ($)",
    value: "TTD ($)"
  },
  {
    label: "TND (ت.د)",
    value: "TND (ت.د)"
  },
  {
    label: "TRY (₺)",
    value: "TRY (₺)"
  },
  {
    label: "TMT (T)",
    value: "TMT (T)"
  },
  {
    label: "USD ($)",
    value: "USD ($)"
  },
  {
    label: "AUD ($)",
    value: "AUD ($)"
  },
  {
    label: "UGX (USh)",
    value: "UGX (USh)"
  },
  {
    label: "UAH (₴)",
    value: "UAH (₴)"
  },
  {
    label: "AED (إ.د)",
    value: "AED (إ.د)"
  },
  {
    label: "GBP (£)",
    value: "GBP (£)"
  },
  {
    label: "USD ($)",
    value: "USD ($)"
  },
  {
    label: "USD ($)",
    value: "USD ($)"
  },
  {
    label: "UYU ($)",
    value: "UYU ($)"
  },
  {
    label: "UZS (лв)",
    value: "UZS (лв)"
  },
  {
    label: "VUV (VT)",
    value: "VUV (VT)"
  },
  {
    label: "EUR (€)",
    value: "EUR (€)"
  },
  {
    label: "VES (Bs)",
    value: "VES (Bs)"
  },
  {
    label: "VND (₫)",
    value: "VND (₫)"
  },
  {
    label: "USD ($)",
    value: "USD ($)"
  },
  {
    label: "USD ($)",
    value: "USD ($)"
  },
  {
    label: "XPF (₣)",
    value: "XPF (₣)"
  },
  {
    label: "MAD (MAD)",
    value: "MAD (MAD)"
  },
  {
    label: "YER (﷼)",
    value: "YER (﷼)"
  },
  {
    label: "ZMW (ZK)",
    value: "ZMW (ZK)"
  },
  {
    label: "ZWL ($)",
    value: "ZWL ($)"
  }
]

export {
  RANGE,
  SALARYRATE,
  employmentStatusChoices,
  EXPERIENCE_CHOICES,
  FUNCTIONAL_AREA_CHOICES,
  INDUSTRY_CHOICES,
  SALARY_CHOICES,
  POSITION_CHOICES,
  EDUCATION_LEVELS,
  JOB_TYPE,
  STATUS,
  DEGREES_DATA,
  CURRENCY,
};
